<template>
  <div
    ref="chartdiv"
    style="width: 100%; height: 500px;"
  />
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themesAnimated)

export default {
  name: 'StackedAreaChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    categoryField: {
      type: String,
      required: true,
    },
    seriesFields: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)

    chart.data = this.data

    const title = chart.titles.create()
    title.text = this.title

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = this.categoryField
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.startLocation = 0.5
    categoryAxis.endLocation = 0.5

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.renderer.minWidth = 0
    valueAxis.tooltip.disabled = true

    // Create series
    this.seriesFields.forEach(field => {
      const series = chart.series.push(new am4charts.LineSeries())
      series.dataFields.valueY = field
      series.dataFields.categoryX = this.categoryField
      series.name = field
      series.tooltipText = '{name}: [bold]{valueY}[/]'
      series.stacked = true
      series.fillOpacity = 0.6
      series.tooltip.getStrokeFromObject = true
      series.tooltip.background.strokeWidth = 3
      series.tooltip.getFillFromObject = false
      series.strokeWidth = 2
      series.stacked = true
    })

    // Add cursor
    chart.cursor = new am4charts.XYCursor()

    // Add legend
    chart.legend = new am4charts.Legend()

    this.chart = chart
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<style scoped>

</style>
