<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-card title="Stocks disponibles pour chaque produit par canal de distribution">
          <ColumnChart3D
            :data="chartData1"
            category-field="channel"
            value-field="stock"
            series-name="Stocks disponibles"
            x-axis-title="Canal de distribution"
            y-axis-title="Stocks disponibles"
          />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Quantités à réapprovisionner pour chaque produit par canal de distribution">
          <stacked-column-chart
            :data="data"
            category-field="channel"
            :series="series"
            x-axis-title="Canal de distribution"
            y-axis-title="Quantités à réapprovisionner"
          />
        </b-card>
      </b-col></b-row>
    <b-row>
      <b-col md="6">
        <b-card title="Niveau de stock de sécurité pour chaque produit par canal de distribution">
          <ColumnChart3D
            :data="data2"
            category-field="product"
            value-field="securityStock"
            series-name="Niveau de stock de sécurité"
            x-axis-title="Produit"
            y-axis-title="Niveau de stock"
          />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Taux de rupture de stock pour chaque produit par canal de distribution">
          <stacked-column-chart
            :data="chartData2"
            category-field="category"
            :series="chartSeries"
            x-axis-title="Canal de distribution"
            y-axis-title="Taux de rupture de stock"
          />
        </b-card>

      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-card title="Niveau de stock moyen pour chaque produit par canal de distribution">
          <StackedColumnChart
            :data="chartData3"
            category-field="canalDistribution"
            :series="series3"
            x-axis-title="Canal de distribution"
            y-axis-title="Niveau de stock moyen"
          />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Taux de satisfaction des clients en termes de disponibilité des produits">
          <donut-chart
            :data="chartData4"
            title="Taux de satisfaction des clients"
            value-field="value"
            category-field="category"
          />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Taux de satisfaction des clients en termes de disponibilité des produits">
          <StackedAreaChart
            :data="chartDataStackedAreaChart"
            category-field="year"
            :series-fields="['motorcycles', 'cars', 'buses']"
            title="Vehicle Sales"
          />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Taux de satisfaction des clients en termes de disponibilité des produits">
          <SimpleColumnChart :chart-data="dataSimpleColumnChart" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card title="Commandes en cours pour chaque produit par canal de distribution">
          <vue-good-table-vue
            :columns="columns"
            :rows="rows"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card title="Historique des commandes passées pour chaque produit par canal de distribution">
          <vue-good-table-vue
            :columns="columns2"
            :rows="rows2"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BCard, BCol, BRow } from 'bootstrap-vue'
import ColumnChart3D from '@/views/m-components/charts/ColumnChart3D.vue'
import VueGoodTableVue from '@/views/m-components/listes/VueGoodTableVue.vue'
import StackedColumnChart from '@/views/m-components/charts/StackedColumnChart.vue'
import DonutChart from '@/views/m-components/charts/DonutChart.vue'
import StackedAreaChart from '@/views/m-components/charts/StackedAreaChart.vue'
import SimpleColumnChart from '@/views/m-components/charts/SimpleColumnChart.vue'

export default {
  name: 'MainVue',
  components: {
    SimpleColumnChart,
    StackedAreaChart,
    DonutChart,
    StackedColumnChart,
    VueGoodTableVue,
    ColumnChart3D,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataSimpleColumnChart: [
        {
          full_name: 'Aly Kane',
          objectif_stock_sortie_1l: 60,
          objectif_stock_sortie_50cl: 40,
          total_objectif: 100,
          total_realisation: 0,
          taux_realisation: 0.0,
        },
        {
          full_name: 'Aly Kane',
          objectif_stock_sortie_1l: 60,
          objectif_stock_sortie_50cl: 40,
          total_objectif: 100,
          total_realisation: 210,
          taux_realisation: 210.0,
        },
      ],
      chartDataStackedAreaChart: [
        {
          year: '2000', motorcycles: 200, cars: 1200, buses: 100,
        },
        {
          year: '2001', motorcycles: 150, cars: 1300, buses: 200,
        },
        {
          year: '2002', motorcycles: 180, cars: 1400, buses: 150,
        },
        {
          year: '2003', motorcycles: 200, cars: 1500, buses: 250,
        },
        {
          year: '2004', motorcycles: 220, cars: 1600, buses: 300,
        },
        // ...
      ],
      //= ===================== Stocks disponibles pour chaque produit par canal de distribution
      chartData1: [
        {
          channel: 'Magasin A',
          stock: 200,
        },
        {
          channel: 'Magasin B',
          stock: 300,
        },
        {
          channel: 'Magasin C',
          stock: 400,
        },
        {
          channel: 'Magasin D',
          stock: 100,
        },
      ],
      //= ===================== Commandes en cours pour chaque produit par canal de distribution
      columns: [
        {
          label: 'Produit',
          field: 'produit',
        },
        {
          label: 'Canal de distribution',
          field: 'canal',
        },
        {
          label: 'Date de commande',
          field: 'dateCommande',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy',
        },
        {
          label: 'Quantité commandée',
          field: 'quantiteCommandee',
          type: 'number',
        },
        {
          label: 'Quantité livrée',
          field: 'quantiteLivree',
          type: 'number',
        },
        {
          label: 'Statut de la commande',
          field: 'statutCommande',
        },
        {
          label: 'Date de livraison prévue',
          field: 'dateLivraisonPrevue',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy',
        },
      ],
      rows: [
        {
          produit: 'Produit 1',
          canal: 'Canal 1',
          dateCommande: '2022-02-15',
          quantiteCommandee: 100,
          quantiteLivree: 50,
          statutCommande: 'En cours',
          dateLivraisonPrevue: '2022-02-25',
        },
        {
          produit: 'Produit 1',
          canal: 'Canal 2',
          dateCommande: '2022-02-10',
          quantiteCommandee: 200,
          quantiteLivree: 0,
          statutCommande: 'En attente',
          dateLivraisonPrevue: '2022-02-20',
        },
        {
          produit: 'Produit 2',
          canal: 'Canal 1',
          dateCommande: '2022-02-01',
          quantiteCommandee: 150,
          quantiteLivree: 100,
          statutCommande: 'En cours',
          dateLivraisonPrevue: '2022-02-15',
        },
        {
          produit: 'Produit 2',
          canal: 'Canal 2',
          dateCommande: '2022-02-05',
          quantiteCommandee: 75,
          quantiteLivree: 75,
          statutCommande: 'Livrée',
          dateLivraisonPrevue: '2022-02-10',
        },
        {
          produit: 'Produit 3',
          canal: 'Canal 1',
          dateCommande: '2022-02-03',
          quantiteCommandee: 50,
          quantiteLivree: 0,
          statutCommande: 'En attente',
          dateLivraisonPrevue: '2022-02-10',
        },
        {
          produit: 'Produit 3',
          canal: 'Canal 2',
          dateCommande: '2022-02-08',
          quantiteCommandee: 200,
          quantiteLivree: 200,
          statutCommande: 'Livrée',
          dateLivraisonPrevue: '2022-02-10',
        },
      ],
      //= ===================== Quantités à réapprovisionner pour chaque produit par canal de distribution
      data: [
        {
          channel: 'Boutique en ligne', product1: 150, product2: 80, product3: 90,
        },
        {
          channel: 'Magasin', product1: 120, product2: 150, product3: 110,
        },
        {
          channel: 'Marché', product1: 80, product2: 100, product3: 70,
        },
      ],
      series: [
        { seriesName: 'Produit 1', valueField: 'product1', color: '#4CAF50' },
        { seriesName: 'Produit 2', valueField: 'product2', color: '#2196F3' },
        { seriesName: 'Produit 3', valueField: 'product3', color: '#FFC107' },
      ],
      //= ===================== Historique des commandes passées pour chaque produit par canal de distribution
      columns2: [
        {
          label: 'Produit',
          field: 'product',
          filterable: true,
        },
        {
          label: 'Canal de distribution',
          field: 'distributionChannel',
          filterable: true,
        },
        {
          label: 'Date de commande',
          field: 'orderDate',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy',
          filterable: true,
        },
        {
          label: 'Quantité commandée',
          field: 'orderedQuantity',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Quantité réceptionnée',
          field: 'receivedQuantity',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Statut de la commande',
          field: 'orderStatus',
          filterable: true,
        },
      ],
      rows2: [
        {
          id: 1,
          product: 'Produit A',
          distributionChannel: 'Canal 1',
          orderDate: '2022-01-01',
          orderedQuantity: 100,
          receivedQuantity: 100,
          orderStatus: 'Livraison effectuée',
        },
        {
          id: 2,
          product: 'Produit A',
          distributionChannel: 'Canal 2',
          orderDate: '2022-02-01',
          orderedQuantity: 150,
          receivedQuantity: 150,
          orderStatus: 'Livraison effectuée',
        },
        {
          id: 3,
          product: 'Produit B',
          distributionChannel: 'Canal 1',
          orderDate: '2022-01-15',
          orderedQuantity: 200,
          receivedQuantity: 150,
          orderStatus: 'En cours de livraison',
        },
        {
          id: 4,
          product: 'Produit B',
          distributionChannel: 'Canal 2',
          orderDate: '2022-02-15',
          orderedQuantity: 250,
          receivedQuantity: 0,
          orderStatus: 'En attente de livraison',
        },
      ],
      // ================ Niveau de stock de sécurité
      data2: [
        { product: 'Produit A', securityStock: 500 },
        { product: 'Produit B', securityStock: 300 },
        { product: 'Produit C', securityStock: 200 },
        { product: 'Produit D', securityStock: 400 },
        { product: 'Produit E', securityStock: 600 },
      ],
      // ================ Taux de rupture de stock pour chaque produit par canal de distribution
      chartData2: [
        {
          category: 'Canal 1',
          product1: 0.2,
          product2: 0.1,
          product3: 0.3,
          product4: 0.4,
        },
        {
          category: 'Canal 2',
          product1: 0.1,
          product2: 0.2,
          product3: 0.4,
          product4: 0.3,
        },
        {
          category: 'Canal 3',
          product1: 0.3,
          product2: 0.1,
          product3: 0.2,
          product4: 0.4,
        },
      ],
      chartSeries: [
        { valueField: 'product1', seriesName: 'Produit 1', color: '#1f77b4' },
        { valueField: 'product2', seriesName: 'Produit 2', color: '#ff7f0e' },
        { valueField: 'product3', seriesName: 'Produit 3', color: '#2ca02c' },
        { valueField: 'product4', seriesName: 'Produit 4', color: '#d62728' },
      ],
      //= =============================================== Niveau de stock moyen pour chaque produit par canal de distribution

      chartData3: [
        {
          canalDistribution: 'Canal 1',
          stockMoyen: 250,
        },
        {
          canalDistribution: 'Canal 2',
          stockMoyen: 350,
        },
        {
          canalDistribution: 'Canal 3',
          stockMoyen: 150,
        },
        {
          canalDistribution: 'Canal 4',
          stockMoyen: 450,
        },
        {
          canalDistribution: 'Canal 5',
          stockMoyen: 300,
        },
      ],
      series3: [
        {
          valueField: 'stockMoyen',
          seriesName: 'Niveau de stock moyen',
          color: '#3f51b5',
        },
      ],

      //= ================================= Taux de satisfaction des clients en termes de disponibilité des produits
      chartData4: [
        { category: 'Très satisfait', value: 30 },
        { category: 'Satisfait', value: 50 },
        { category: 'Peu satisfait', value: 15 },
        { category: 'Pas satisfait', value: 5 },
      ],

    }
  },
}
</script>

<style scoped>

</style>
